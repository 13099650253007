<template>
  <div id="app">
    <v-app id="inspire">
      <v-app-bar app>
        <v-toolbar dense fixed color="#3e008a">
          <v-container class="bg-surface-variant">
            <v-row no-gutters>
              <v-col sm="4">
                <v-sheet class="ma-2 pa-2" color="#3e008a">
                  <img :width="200" src="@/assets/logo-voldex.svg" />
                </v-sheet>
              </v-col>
            </v-row>
          </v-container>
          <v-btn
            v-if="isLoggedIn && photoURL !== ''"
            variant="outlined"
            icon
            :style="
              'background-image: url(' +
              photoURL +
              '); background-size: cover; background-position: center; background-repeat: no-repeat;'
            "
            color="secondary"
            @click.stop="drawer = !drawer"
          >
          </v-btn>
          <v-btn
            v-if="isLoggedIn && photoURL === ''"
            variant="outlined"
            icon
            color="secondary"
            @click.stop="drawer = !drawer"
            ><i class="fa fa-user"></i>
          </v-btn>
        </v-toolbar>
        <v-spacer></v-spacer>
      </v-app-bar>
      <v-main style="height: 250px">
        <v-navigation-drawer v-model="drawer" temporary location="right">
          <v-list-item :title="name"></v-list-item>

          <v-divider></v-divider>

          <v-list density="compact" nav>
            <v-list-item
              prepend-icon="fa fa-moon-o"
              title="Dark/Light Mode"
              value="mode"
              @click="changeMode"
            >
            </v-list-item>
            <v-list-item
              prepend-icon="fa fa-sign-out"
              title="Logout"
              value="logout"
              @click="logout"
            >
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-navigation-drawer permanent theme="dark" v-if="isLoggedIn">
          <v-list density="compact" nav class="text-left">
            <v-list-item
              title="Home"
              prepend-icon="fa fa-home"
              value="home"
              @click="$router.push('/home')"
            ></v-list-item>
            <v-list-item
              title="Users"
              prepend-icon="fa fa-user"
              value="user"
              @click="$router.push('/user')"
            ></v-list-item>
            <v-list-item
              title="Logs"
              prepend-icon="fa fa-list"
              value="user"
              @click="$router.push('/log')"
            ></v-list-item>
            <v-list-item
              title="Games"
              prepend-icon="fa fa-archive"
              value="package"
              @click="$router.push('/package')"
            ></v-list-item>
            <v-list-item
              title="Versions"
              prepend-icon="fa fa-history"
              value="history"
              @click="$router.push('/history')"
            ></v-list-item>
            <v-list-item
              title="Temp. Packages"
              prepend-icon="fa fa-file-archive-o"
              value="temp-packages"
              @click="$router.push('/temp-packages')"
            ></v-list-item>
          </v-list>
        </v-navigation-drawer>

        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { People } from "@/services/people";

export default {
  name: "AppView",
  data() {
    return {
      name: "",
      photoURL: "",

      drawer: null,
      permissions: {},
    };
  },
  computed: {
    hasAdminPermission() {
      return (
        this.permissions &&
        this.permissions.roles &&
        this.permissions.roles.includes("admin")
      );
    },
    isLoggedIn: function () {
      return this.$store.getters.isAuthenticated;
    },
  },
  async mounted() {
    if (this.$store.getters.isAuthenticated) {
      const user = await this.$store.getters.stateUser;
      this.name = user.displayName;
      this.photoURL = user.photoURL;

      const peopleId = user.peopleId
        ? user.peopleId
        : localStorage.getItem("peopleId");

      this.permissions = (await People.getPeoplePermissions(peopleId)).data;
    } else {
      //put a delay to wait for the store to be populated
      await new Promise((resolve) => setTimeout(resolve, 1000));

      if (this.$store.getters.isAuthenticated) {
        const user = await this.$store.getters.stateUser;
        this.name = user.displayName;
        this.photoURL = user.photoURL;

        const peopleId = user.peopleId
          ? user.peopleId
          : localStorage.getItem("peopleId");

        this.permissions = (await People.getPeoplePermissions(peopleId)).data;
      }
    }
  },
  methods: {
    changeMode() {
      const modeValue = localStorage.getItem("dark-mode");

      if (modeValue === false || modeValue === "false" || modeValue === null) {
        localStorage.setItem("dark-mode", true);
      } else {
        localStorage.setItem("dark-mode", false);
      }

      window.location.reload();
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push("/");
        })
        .catch((error) => {
          alert(error.message);
          this.$router.push("/");
        });
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-container,
.v-row,
.v-col,
.v-sheet {
  background-color: #3e008a !important;
}
</style>
