import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

export async function userTokenToAccessData() {
  let token;

  try {
    token = await firebase.auth().currentUser.getIdToken();

    localStorage.setItem('userToken', token)

  } catch (e) {
    token = localStorage.getItem('userToken')
  }

  return token
}
