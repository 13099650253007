// http://remote-config-qa.us-east-2.elasticbeanstalk.com/publish
import axios from "axios";
import { userTokenToAccessData } from "@/services/helper";

export class People {
  static async getPeople() {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people";

    let token = await userTokenToAccessData();

    return await axios.get(postURl, {
      headers: { session: token },
    });
  }

  static async findPeople(email) {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people/email/" + email;

    return await axios.get(postURl, {});
  }

  static async getPermissions() {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people/permissions";

    let token = await userTokenToAccessData();

    return await axios.get(postURl, {
      headers: { session: token },
    });
  }

  static async getPeoplePermissions(peopleId) {
    const postURl =
      process.env.VUE_APP_AWS_MAIN_URL + "people/permissions/" + peopleId;

    let token = await userTokenToAccessData();

    return await axios.get(postURl, {
      headers: { session: token },
    });
  }

  static async updatePermissions(data) {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people/permissions";

    let token = await userTokenToAccessData();

    return await axios.post(postURl, data, {
      headers: { session: token },
    });
  }

  static async deletePermissions(peopleId, gameId) {
    const postURl =
      process.env.VUE_APP_AWS_MAIN_URL +
      `people/permissions/${peopleId}/${gameId}`;

    let token = await userTokenToAccessData();

    return await axios.delete(postURl, {
      headers: { session: token },
    });
  }

  static async deletePeople(peopleId) {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + `people/${peopleId}`;

    let token = await userTokenToAccessData();

    return await axios.delete(postURl, {
      headers: { session: token },
    });
  }

  static async updatePeople(data, ignoresLog = false) {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people";

    let token = await userTokenToAccessData();

    return await axios.put(postURl, data, {
      headers: { session: token, ignoresLog: ignoresLog },
    });
  }

  static async updatePeopleRoles(data) {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people/roles";

    let token = await userTokenToAccessData();

    return await axios.put(postURl, data, {
      headers: { session: token },
    });
  }

  static async invitePeople(email) {
    const postURl = process.env.VUE_APP_AWS_MAIN_URL + "people/invite";

    let token = await userTokenToAccessData();

    return await axios.post(
      postURl,
      { email: email },
      {
        headers: { session: token },
      }
    );
  }
}
