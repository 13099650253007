import { createRouter, createWebHistory } from "vue-router";
import { store } from "@/store";
import { People } from "@/services/people";

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/Home"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/log",
    name: "log",
    component: () => import("@/views/Log"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("@/views/User"),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: "/package",
    name: "package",
    component: () => import("@/views/Package"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/drive/:pathMatch(.*)*",
    name: "drive",
    component: () => import("@/views/Drive"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/History"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/temp-packages",
    name: "tempPackages",
    component: () => import("@/views/TempPackages"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !store.getters.isAuthenticated) {
    next("/");
  } else {
    let requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

    if (requiresAdmin) {
      const peopleId = store.getters.stateUser.peopleId
        ? store.getters.stateUser.peopleId
        : localStorage.getItem("peopleId");

      const permissions = await People.getPeoplePermissions(peopleId);

      if (permissions.data.roles && permissions.data.roles.includes("admin")) {
        next();
        return;
      } else {
        alert("permission denied");
        next("/home");
      }
    }
        
    next();
  }
});

export default router;
