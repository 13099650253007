import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import $bus from "@/event.js";
import { store } from "@/store";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import { aliases, mdi } from "vuetify/iconsets/mdi";
import { People } from "@/services/people";

const modeValue = localStorage.getItem("dark-mode");

let theme = "light";

if (modeValue === true || modeValue === "true") {
  localStorage.setItem("dark-mode", true);
  theme = "dark";
}

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: theme,
  },
  icons: {
    defaultSet: "mdi",
    aliases,
    sets: {
      mdi,
    },
  },
});

const isQA = process.env.VUE_APP_QA === "true";

document.title = `${isQA ? "(ENV) - " : ""}Remote Config Panel`;

const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APP_ID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged(async (user) => {
  if (user) {
    const peopleExist = await People.findPeople(user.email);

    if (peopleExist.data.length === 0) {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.setItem("contactAdmin", true);
          window.location.href = "/";
        })
        .catch((error) => {
          alert(error.message);
          window.location.href = "/";
        });
    } else {
      const data = {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
        id: peopleExist.data.id,
        photoURL: user.photoUrl,
      };

      await People.updatePeople(data, true);

      const token = await firebase.auth().currentUser.getIdToken();

      user.peopleId = peopleExist.data.id;

      await store.dispatch("setUser", user);
      await store.dispatch("setToken", token);
      localStorage.setItem("userToken", token);
      localStorage.setItem("peopleId", user.peopleId);
    }
  } else {
    localStorage.setItem("userToken", null);
    localStorage.setItem("peopleId", null);

    await store.dispatch("setUser", null);
    await store.dispatch("setToken", null);
  }
});
const app = createApp(App).use(vuetify).use(store).use(router);
app.config.globalProperties.$bus = $bus;
app.component("EasyDataTable", Vue3EasyDataTable);
app.mount("#app");
