import firebase from "firebase/app";
import createPersistedState from "vuex-persistedstate";
import Vuex from "vuex";

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: null,
    token: null,
  },
  getters: {
    isAuthenticated: (state) => !!state.user,
    stateUser: (state) => state.user,
    stateUserPermission: (state) => state.userPermission,
    tokenUser: (state) => state.token,
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setUserPermission(state, userPermission) {
      state.userPermission = userPermission;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
  actions: {
    setUser(context, user) {
      context.commit("setUser", user);
    },
    setUserPermission(context, userPermission) {
      context.commit("setUserPermission", userPermission);
    },
    setToken(context, token) {
      context.commit("setToken", token);
    },
    async signOut() {
      await firebase.auth().signOut();
    },
  },
});
